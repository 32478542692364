<mat-vertical-stepper
    class="income-wizard"
    [linear]="false"
    #stepper
    (selectionChange)="onStepChange($event)"
>
    <mat-step [stepControl]="firstFormGroup">
        <form
            [formGroup]="firstFormGroup"
            autocomplete="off"
            class="mat-dialog-content"
        >
            <ng-template matStepLabel>{{ title }}</ng-template>

            <div class="form-row">
                <mat-form-field color="accent" class="full_width">
                    <mat-label>Descripción</mat-label>
                    <input matInput #inputstate formControlName="notes" />
                    <mat-error *ngIf="firstFormGroup.invalid">{{
                        getErrorMessage()
                    }}</mat-error>
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Solicitante</mat-label>
                    <input matInput #inputstate formControlName="payee" />
                    <mat-error *ngIf="firstFormGroup.invalid">{{
                        getErrorMessage()
                    }}</mat-error>
                </mat-form-field>
            </div>

            <div class="form-row">
                <mat-form-field color="accent">
                    <mat-label>Cantidad</mat-label>
                    <input
                        matInput
                        #inputstate
                        formControlName="amount"
                        type="number"
                    />
                    <mat-error *ngIf="firstFormGroup.invalid">{{
                        getErrorMessage()
                    }}</mat-error>
                </mat-form-field>

                <mat-form-field color="accent">
                    <mat-label>Ubicacion</mat-label>
                    <mat-select
                        formControlName="locationId"
                        [disabled]="isEdit"
                    >
                        <mat-option
                            *ngFor="let location of data.locations"
                            [value]="location.locationId"
                        >
                            {{ location.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="!isCalendar" class="form-row">
                <mat-form-field color="accent">
                    <mat-label>Categoria</mat-label>
                    <mat-select
                        formControlName="categoryId"
                        (selectionChange)="categorySelected($event.value)"
                    >
                        <mat-option
                            *ngFor="let category of data.categories"
                            [value]="category.categoryId"
                        >
                            {{ category.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="accent">
                    <mat-label>Subcategoria</mat-label>
                    <mat-select formControlName="subcategoryId">
                        <mat-option
                            *ngFor="let subcategory of subcategories"
                            [value]="subcategory.subcategoryId"
                        >
                            {{ subcategory.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                
            </div>
            <div>
                <mat-form-field
                            class="time-date"
                        >
                            <input
                                matInput
                                [matDatepicker]="picker"
                                formControlName="dateCreated"
                            />
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle
                                matIconSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                        </mat-form-field>
            </div>
            <div class="buttons">
                <button
                    mat-raised-button
                    matStepperNext
                    class="button-next"
                    [disabled]="!firstFormGroup.valid"
                >
                    Siguiente
                </button>
                <button mat-raised-button (click)="close()">Cancelar</button>
            </div>
        </form>
    </mat-step>
    <mat-step label="Agregar eventos">
        <div class="spinner" *ngIf="loading; else content">
            <!-- Loading indicator -->
            <mat-spinner diameter="30"></mat-spinner>
        </div>
        <ng-template #content>
            <p>
                El ingreso se guardó correctamente, cerrar el dialogo si no hay
                eventos por agregar
            </p>
            <app-events [eventData]="eventData"></app-events>
            <div class="buttons">
                <button mat-raised-button [disabled]="!canSave" (click)="saveEvents()">Guardar</button>
                <button mat-raised-button (click)="close()">Salir</button>
            </div>
        </ng-template>
    </mat-step>
</mat-vertical-stepper>
